import PageLoading from "@/components/page-loading";
import Firm from "@/models/firm";
import firmApi from "@/services/apis/firm/firm-api";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./auth-context-provider";

class FirmContextValues {
    firm: Firm = new Firm();

    constructor(init?: Partial<FirmContextValues>) {
        Object.assign(this, init);
    }
}

export const FirmContext = createContext<FirmContextValues>(new FirmContextValues());

interface Props extends PropsWithChildren<Record<never, any>> { }

const FirmContextProvider = (props: Props) => {
    const [firm, setFirm] = useState<Firm>();

    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (!!authContext.hasRunAuth)
            firmApi.getCurrentFirm(setFirm);
    }, [authContext.hasRunAuth]);

    return (
        <>
            {
                !!firm
                    ? <FirmContext.Provider
                        value={{
                            firm: firm || new Firm()
                        }}
                    >
                        {props.children}
                    </FirmContext.Provider>
                    : <PageLoading />
            }

        </>
    );
}

export default FirmContextProvider;