import Conditional from "@/components/conditional";
import InvoicePayment from "@/models/invoice-payment";
import { formatAsCurrency } from "@/services/formatters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

interface Props {
    Payments: InvoicePayment[];
    Title: string;
}

function PaymentTable(props: Props) {
    const getPaymentDescriptor = (payment: InvoicePayment)=>{
        if(payment.IsCancelled)
            return 'Cancelled ';
        if(payment.PaymentStatus == 2)
            return 'Failed ';
        return '';
    }

    return (
        <>
            {!!props.Payments.length &&
                <Row>
                    <Table striped hover>
                        <thead>
                            <tr>
                                <th>{props.Title}</th>
                                <th className="f-right-col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.Payments.map((payment, index) => {
                                return (<tr key={index}>
                                    <td>
                                        {`${getPaymentDescriptor(payment)}Payment made ${payment.DateDisplay}`}
                                        <Conditional condition={!!payment.PaymentIdentifier}>
                                            <Link to={`/receipt/${payment.PaymentIdentifier}`}>
                                                <FontAwesomeIcon icon="receipt" />
                                            </Link>
                                        </Conditional>
                                    </td>
                                    <td className="f-right-col">{formatAsCurrency(payment.Amount)}</td>
                                </tr>);
                            })}
                        </tbody>
                    </Table>
                </Row>
            }
        </>
    )
}

export default PaymentTable;