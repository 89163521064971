import Header from "@/components/layout/header";
import { Alert } from "react-bootstrap";

function InvalidUser() {
    return (
        <>
            <header>
                <Header />
            </header>
            <article>
                <Alert variant='danger'>You do not have permission to view this invoice.</Alert>
            </article>
        </>
    )
}

export default InvalidUser;