import ProjectTitleBar from "@/components/layout/project-title-bar";
import ScrollablePage from "@/components/scrollable-page";
import PayFieldsComponent from "@/pages/billing/pay-fields";
import { Col, Row } from "react-bootstrap";
import './credit-card-payment.scss';
import PaymentBreakdown from "./payment-breakdown";

function CreditCardPayment() {

  return (
    <ScrollablePage className='f-credit-card-payment' title='Pay With Card'>
      <ProjectTitleBar />
      <Row className="f-body-row">
        <Col xs="12" lg="8" className="f-payfield-col">
          <PayFieldsComponent paymentType='card' />
        </Col>
        <Col xs={12} lg="4" className="d-none d-lg-block">
          <PaymentBreakdown screen="payment" />
        </Col>
      </Row>
    </ScrollablePage>
  );
}

export default CreditCardPayment;