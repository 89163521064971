import { SeverityLevel } from '@microsoft/applicationinsights-web';
import supportApi from './apis/support-api';

export function logException(exception: Error, notifySupport:boolean = false, additionalProperties?: any): void {
    import('@/services/app-insights').then(appInsights => {
        appInsights.appInsights.trackException({
            error: exception,
            exception: exception,
            severityLevel: SeverityLevel.Error,
            properties: additionalProperties
        });

        if (notifySupport)
            supportApi.sendSupportEmail(exception);
    });
}

export function logExceptionToSupport(exception: Error, additionalProperties?: any): void {
    logException(exception, true, additionalProperties);
}