import { BrowserCacheLocation } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: (window as any).REACT_APP_B2C_CLIENT_ID || '',
    authority: `https://${(window as any).REACT_APP_B2C_INSTANCE}/${(window as any).REACT_APP_B2C_DOMAIN}/B2C_1A_FACTOR_PAY_LINK`,
    redirectUri: window.location.origin,
    knownAuthorities: [(window as any).REACT_APP_B2C_INSTANCE || '']
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
    scopes: ["profile", "offline_access"]
};

export const tokenRequest = {
    scopes: [
        `https://${(window as any).REACT_APP_B2C_DOMAIN}/factor-pay-api/global.readwrite`
    ]
};

export default msalConfig;
