import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import AuthenticatedAppRoot from "./authenticated-app-root";
import UncaughtErrorBoundary from "../uncaught-error-boundary";
import PageLoading from "@/components/page-loading";
import ViewInvoice from "../billing/view-invoice";
import CreditCardPayment from "../billing/credit-card-payment";
import ACHPayment from "../billing/ach-payment";
import Receipt from "@/pages/billing/receipt";
import Support from "../support";

function AuthenticatedAppRoutes() {
    const getRouter = () => {
        let routes = createRoutesFromElements(
            <Route path="/" element={<AuthenticatedAppRoot />} errorElement={<UncaughtErrorBoundary />} >
                <Route key="credit-card" path="/credit" element={<CreditCardPayment />} />
                <Route key="ach" path="/ach" element={<ACHPayment />} />
                <Route key="view-invoice" path="/invoice" element={<ViewInvoice />} />
                <Route key="receipt" path="/receipt/:paymentId" element={<Receipt />} />
                <Route key="support" path="/support" element={<Support />} />
                <Route key="landing" path="/" element={<ViewInvoice />} />
                <Route key="default" path="*" element={<PageLoading />} />
            </Route>
        );

        return createBrowserRouter(routes);
    }

    return (
        <>
            <RouterProvider router={getRouter()} />
        </>
    );
}

export default AuthenticatedAppRoutes;