import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import './App.scss'
import AuthContextProvider from './contexts/auth-context-provider'
import FirmContextProvider from './contexts/firm-context-provider'
import UserContextProvider from './contexts/user-context-provider'
import AuthenticatedAppRoutes from './pages/auth/authenticated-app-routes'
import Unauthorized from './pages/auth/unauthorized'
import initializeCommonIcons from './services/icons'
import UncaughtErrorBoundary from './pages/uncaught-error-boundary'
import Support from './pages/support'
import withAITracking from '@/services/app-insights';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

function App() {
  initializeCommonIcons();

  const getRouter = () => {
    let routes = createRoutesFromElements(
      <Route path="/" errorElement={<UncaughtErrorBoundary />} >
        <Route key="support" path="/support" element={<Support />} />
        <Route path="/" element={<Unauthorized />} ></Route>
      </Route>
    );

    return createBrowserRouter(routes);
  }

  return (
    <>
      <AuthContextProvider>
        <AuthenticatedTemplate>
          <FirmContextProvider>
            <UserContextProvider>
              <AuthenticatedAppRoutes />
            </UserContextProvider>
          </FirmContextProvider>
        </AuthenticatedTemplate >
        <UnauthenticatedTemplate>
          <RouterProvider
            router={getRouter()}
          />
        </UnauthenticatedTemplate>
      </AuthContextProvider>
      <ToastContainer
        position="bottom-center"
        theme='colored'
      />
    </>
  )
}

export default withAITracking(App);
