class Firm {
    Id: number = 0;
    CoreId: number = 0;
    MerchantId: string = '';
    MerchantName: string = '';

    constructor(init?: Partial<Firm>) {
        Object.assign(this, init);
    }
}

export default Firm;