import InvoicePayment from "./invoice-payment";

class Invoice {
    InvoiceNumber: string = '';
    Amount: number = 0;
    Date: string ='';
    DueDate: string ='';
    PaidStatus: string ='';
    RemainingBalance: number = 0;
    CompanyName: string = '';
    ProjectName: string = '';
    ProjectNumber: string = '';
    FirmName: string = '';
    HasHourlyDetail: boolean = false;
    HasExpenseAttachments: boolean = false;
    CanBeViewed: boolean = false;
    CanPayWithACH: boolean = false;
    CanPayWithCC: boolean = false;
    PdfEncryptionValue: string = '';
    PdfEncryptionSalt: string = '';
    CreditCardConvenienceFeeSetting: InvoicePaymentFeeSetting = new InvoicePaymentFeeSetting();
    ACHConvenienceFeeSetting: InvoicePaymentFeeSetting = new InvoicePaymentFeeSetting();
    Payments:InvoicePayment[] = [];
    RefundedPayments:InvoicePayment[] = [];

    constructor(init?: Partial<Invoice>) {
        Object.assign(this, init);
    }
}

export class InvoicePaymentFeeSetting{
    FeeAmount: number = 0;
    IsPercentage: boolean = false;

    constructor(init?: Partial<InvoicePaymentFeeSetting>) {
        Object.assign(this, init);
    }
}

export default Invoice;