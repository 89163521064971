import Header from "@/components/layout/header";
import Support from "@/pages/support";
//import { logExceptionToSupport } from "@/services/logging";
import { useRouteError } from "react-router-dom";


function UncaughtErrorBoundary() {
    const error = useRouteError();

    console.error(error);

    // if (isRouteErrorResponse(error)) {
    //     logExceptionToSupport({ message: error.statusText } as Error, error);
    // } else if (error instanceof Error) {
    //     logExceptionToSupport(error as Error, {});
    // } else if (typeof error === 'string') {
    //     logExceptionToSupport({ message: error } as Error, {});
    // } else {
        console.error(error);
    // }

    return (
        <>
            <header>
                <Header />
            </header>
            <article>
                <Support />
            </article>
        </>
    );
}

export default UncaughtErrorBoundary;