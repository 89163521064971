import Conditional from "@/components/conditional";
import PageLoading from "@/components/page-loading";
import Invoice from "@/models/invoice";
import InvalidUser from "@/pages/auth/invalid-user";
import invoiceApi from "@/services/apis/invoice/invoice-api";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./user-context-provider";


class InvoiceContextValues {
    invoice: Invoice = new Invoice();
    reloadInvoice: () => void = () => { };

    constructor(init?: Partial<InvoiceContextValues>) {
        Object.assign(this, init);
    }
}

export const InvoiceContext = createContext<InvoiceContextValues>(new InvoiceContextValues());

interface Props extends PropsWithChildren<Record<never, any>> { }

function InvoiceContextProvider(props: Props) {
    const [invoice, setInvoice] = useState<Invoice>();

    const userContext = useContext(UserContext);

    useEffect(() => {
        reloadInvoice();
    }, [userContext.user]);

    const reloadInvoice = () => {
        if (!!userContext.user?.CanViewInvoice)
            invoiceApi.getInvoice(setInvoice);
    }

    return (
        <>
            <Conditional condition={!!invoice && !!userContext.user?.CanViewInvoice}>
                <InvoiceContext.Provider
                    value={{
                        invoice: invoice || new Invoice(),
                        reloadInvoice
                    }}
                >
                    {props.children}
                </InvoiceContext.Provider>
            </Conditional>
            <Conditional condition={!!userContext.user && !userContext.user.CanViewInvoice}>
                <InvalidUser />
            </Conditional>
            <Conditional condition={!userContext.user || (!invoice && !!userContext.user?.CanViewInvoice)}>
                <PageLoading />
            </Conditional>

        </>
    );
}

export default InvoiceContextProvider;