import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
    config: {
        connectionString: (window as any).REACT_APP_APP_INSIGHTS_CONNECTION,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true
    }
});
ai.loadAppInsights();
const withAiTracking = (Component: React.ComponentType<unknown>) => withAITracking(reactPlugin, Component);
export default withAiTracking;
export const appInsights = ai.appInsights;