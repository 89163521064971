import { FunctionComponent, PropsWithChildren } from "react";

interface Props extends PropsWithChildren<Record<never, any>> {
    condition: boolean;
    alternateComponent?: JSX.Element;
}

const Conditional: FunctionComponent<Props> = ({ children, ...props }) => {
    return (
        <>
            {props.condition &&
                children
            }
            {!props.condition && !!props.alternateComponent &&
                props.alternateComponent
            }
        </>
    )
}

export default Conditional;