import Api from '@/services/api';

class SupportApi {
    sendSupportEmail(exception: Error): Promise<void> {
        var request = new SupportApiRequest(exception, window.location.href);
        return Api.post({ url: '/api/v1/support', body: request, onKnownFailure: (error) => { console.log(error) } });
    }
}

class SupportApiRequest {
    ExceptionDetails: String;
    Url: String;
    constructor(exception: Error, url: String) {
        this.ExceptionDetails = `${exception.message} Stack Trace: ${exception.stack}`;
        this.Url = url;
    }
}

export default new SupportApi();