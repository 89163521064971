import { Col, Container, Navbar, Row } from "react-bootstrap";

import './headers.scss';
import { Link } from "react-router-dom";
import Logo from "./logo";
import { FirmContext } from "@/contexts/firm-context-provider";
import { useContext, useEffect, useState } from "react";
import { InvoiceContext } from "@/contexts/invoice-context";
import Invoice from "@/models/invoice";

function Header() {
    const [invoice, setInvoice] = useState<Invoice>();

    const firmContext = useContext(FirmContext);
    const invoiceContext = useContext(InvoiceContext);

    useEffect(() => {
        setInvoice(invoiceContext.invoice);
    }, [invoiceContext.invoice]);

    const getTitle = () => {
        if (!invoice?.ProjectNumber)
            return invoice?.ProjectName || '';
        return `${invoice?.ProjectNumber} - ${invoice?.ProjectName}`;
    }
    return (
        <Navbar collapseOnSelect={true} className="navbar-default f-main-navigation" expand="lg" fixed="top">
            <div className="f-container">
                <Row>
                    <Navbar.Brand as={Link} to='/'>
                        {`${firmContext.firm.MerchantName}`}
                    </Navbar.Brand>
                </Row>
                <Row className='f-subheader-row'>
                    <Col className="f-firm-details" xs={12} lg={6}>
                        <Row className='f-firm-row'>
                            <span>Client Portal</span>
                        </Row>
                        <Row className='f-logo-row'>
                            <span>Connected by <b>Factor A/E</b></span>
                        </Row>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Row className="f-project-title-row">
                            <span>{getTitle()}</span>
                        </Row>
                        {!!invoice?.CompanyName &&
                            <Row className="f-company-name-row">
                                <span>{invoice?.CompanyName}</span>
                            </Row>
                        }
                    </Col>
                </Row>
            </div>
        </Navbar >
    );

}

export default Header;