import { FunctionComponent, PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';


interface Props extends PropsWithChildren<Record<never, any>> {
    loading: boolean;
}

const LoadableComponent: FunctionComponent<Props> = ({ children, ...props }) => {
    return (
        <>
            {!props.loading &&
                children
            }
            {props.loading &&
                <div className='f-loading-spinner'>
                    <Spinner animation="border" role="status" variant="primary">
                    </Spinner>
                </div>
            }
        </>
    );
};

export default LoadableComponent;