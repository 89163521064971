import "./support.scss";

import { Card } from 'react-bootstrap';

function Support() {
    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <Card body className="f-card support">
            <div>
                <section className="page-header">
                    <h2 className="page-title content-main-heading">Well, this is awkward.</h2>
                </section>

                <hr />

                <p className="lead">Either we did something you didn't expect, or you did something we didn't expect. Whatever the case may be, take a deep breath and let us help you with that.</p>
                <br />
                <h3>Try this first</h3>
                <ol>
                    <li>First, go back and
                        <a href={"#"} onClick={reloadPage}> give that another try</a>
                        . It may have just been a one-time thing.</li>
                    <li>If that doesn't take, please <a href="mailto:help@factorapp.com">send us a note</a> describing what happened or how we can help you, and we'll get back to you soon.</li>
                </ol>

                <p className="f-support-sad">:(</p>
            </div>
        </Card>
    );
};
 
export default Support;
