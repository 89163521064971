import PageLoading from "@/components/page-loading";
import User from "@/models/user";
import { PropsWithChildren, createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./auth-context-provider";
import userApi from "@/services/apis/user/user-api";

class UserContextValues {
    user: User = new User;

    constructor(init?: Partial<UserContextValues>) {
        Object.assign(this, init);
    }
}

export const UserContext = createContext<UserContextValues>(new UserContextValues());

interface Props extends PropsWithChildren<Record<never, any>> { }

const UserContextProvider = (props: Props) => {
    const [user, setUser] = useState<User | undefined>(undefined);
    const authContext = useContext(AuthContext);

    useEffect(() => {
         if (!!authContext.hasRunAuth)
             userApi.getCurrentUser(setUser);
    }, [authContext.hasRunAuth]);
    
    return (
        <>
            {user !== undefined
                ? <UserContext.Provider value={{ user }}>
                    {props.children}
                </UserContext.Provider>
                : <PageLoading />
            }
        </>);
}

export default UserContextProvider;