import { msalInstance } from "@/main";
import { tokenRequest } from "@/services/auth-config";
import $ from 'jquery';
import { useEffect, useState } from "react";

import LoadableComponent from "../../components/loadable-component";
import './pay-fields.scss';

interface Props {
    paymentType: 'card' | 'ach';
}

function PayFieldsComponent(props: Props) {
    const [payFieldSource, setPayFieldSource] = useState('');

    useEffect(() => {
        generatePayFields();
    }, [props.paymentType])

    const generatePayFields = () => {
        msalInstance.acquireTokenSilent(tokenRequest).then(response => {
            $.ajax({
                url: `${(window as any).REACT_APP_PROXY_URL}/pay-field/${props.paymentType == 'card' ? 'credit-card' : 'ach'}`,
                type: 'GET',
                contentType: 'application/json',
                headers: { 'Authorization': `Bearer ${response.accessToken}` },
                success: () => { },
                error: () => { },
                xhrFields: {
                    withCredentials: true
                }
            })
                .then((result: string) => {
                    setPayFieldSource(result);
                })
                .catch((error: any) => {
                    //api.handleAjaxErrorResponse(error, false, "Error generating report.", (error: any) => { setIsLoading(false); api.knownErrorFallback(error); });
                });
        })
    }

    return (
        <div className="f-pay-fields">
            <LoadableComponent loading={!payFieldSource}>
                <iframe className='f-pay-field-frame' srcDoc={payFieldSource} />
            </LoadableComponent>
        </div>
    );
}

export default PayFieldsComponent