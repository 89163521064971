import { Col, Container, Navbar, Row } from "react-bootstrap";
import './title-bar.scss';

interface Props{
    className?: string;
    title: string;
    subTitle?: string;
    popoverContents?: string;

}

function TitleBar(props:Props){
    return (
        <Navbar className={props.className ? `title-bar ${props.className}` : 'title-bar'} variant="dark">
            <Navbar.Brand>
                <Container>
                    <Row>
                        <Col className='f-title-col'>
                            <span>{props.title}</span>
                        </Col>
                    </Row>
                    {!!props.subTitle &&
                        <Row>
                            <Col>
                                <h5>{props.subTitle}</h5>
                            </Col>
                        </Row>
                    }
                </Container>
            </Navbar.Brand>
            
        </Navbar >
    );
}

export default TitleBar;