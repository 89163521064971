import PageLoading from "@/components/page-loading";
import { useAccount, useMsal } from "@azure/msal-react";
import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

class AuthContextValues {
    hasRunAuth: boolean = false;
    idTokenHint?: string;
    setIdTokenHint: (idTokenHint?: string) => void = () => { };

    constructor(init?: Partial<AuthContextValues>) {
        Object.assign(this, init);
    }
}

export const AuthContext = createContext<AuthContextValues>(new AuthContextValues());

interface Props extends PropsWithChildren<Record<never, any>> { }

const AuthContextProvider = (props: Props) => {
    const [hasRunAuth, setHasRunAuth] = useState<boolean | undefined>(undefined);
    const [idTokenHint, setIdTokenHint] = useState<string>();
    const { instance, inProgress } = useMsal();
    const account = useAccount();

    useEffect(() => {
        if (inProgress === 'none') {
            if (account != null) {
                setHasRunAuth(true);
            }
            else {
                setHasRunAuth(false);
            }
        }
    }, [account, inProgress]);

    return (
        <>
            {hasRunAuth !== undefined
                ? <AuthContext.Provider value={{ hasRunAuth, idTokenHint, setIdTokenHint }}>
                    {props.children}
                </AuthContext.Provider>
                : <PageLoading />
            }
        </>);
}

export default AuthContextProvider;