import Conditional from "@/components/conditional";
import ProjectTitleBar from "@/components/layout/project-title-bar";
import LoadableComponent from "@/components/loadable-component";
import PdfViewer from "@/components/pdf-viewer";
import ScrollablePage from "@/components/scrollable-page";
import { InvoiceContext } from "@/contexts/invoice-context";
import Invoice from "@/models/invoice";
import invoicePdfApi from "@/services/apis/invoice/invoice-pdf-api";
import { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, CardBody, Col, Row } from "react-bootstrap";
import PaymentBreakdown from "./payment-breakdown";
import './view-invoice.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

function ViewInvoice() {
    const [invoice, setInvoice] = useState<Invoice>();
    const [invoicePdf, setInvoicePdf] = useState<string>();
    const [documentType, setDocumentType] = useState<'invoice' | 'hourly' | 'attachment'>('invoice');

    const invoiceContext = useContext(InvoiceContext);

    useEffect(() => {
        setInvoice(invoiceContext.invoice);
    }, [invoiceContext.invoice]);

    useEffect(() => {
        setInvoicePdf(undefined);
        if (!!invoice && !!invoice.CanBeViewed)
            invoicePdfApi.getInvoicePdf(documentType, invoice.PdfEncryptionValue, invoice.PdfEncryptionSalt, setInvoicePdf);
    }, [documentType, invoice]);

    const getInvoiceLink = (linkDocumentType: 'invoice' | 'hourly' | 'attachment', label: string) => {
        if (linkDocumentType == documentType)
            return <span>{label}</span>;
        return <a onClick={() => setDocumentType(linkDocumentType)}>{label}</a>;
    }

    const hasRemainingBalance = () => {
        return (invoice?.RemainingBalance || 0) > 0;
    }

    const viewInvoice = (docType: string) => {
        invoicePdfApi.getInvoicePdf(docType, invoiceContext.invoice.PdfEncryptionValue, invoiceContext.invoice.PdfEncryptionSalt, openPdfInNewTab);
    }

    const openPdfInNewTab = (pdf: string) => {
        const pdfWindow = window.open("") as any;
        pdfWindow.document.write(
            `<embed src='data:application/pdf;base64,${pdf}' type="application/pdf" width="100%" height="100%"></embed>`
        );
    }

    return (
        <ScrollablePage className='f-view-invoice' title='Invoice Payment'>
            <Conditional condition={!!invoice && !!invoice.CanBeViewed}>
                <ProjectTitleBar />
                <Row className="f-body-row">
                    <Col xs="12" lg="8">
                        <Card className='f-card f-invoice-card'>
                            <CardBody>
                                <Row className='f-card-header-row'>
                                    <Col xs={12} lg={6}>
                                        <h3>Invoice Payment</h3>
                                        <span>View your invoice below and select a payment option.</span>
                                        <Row className="f-doc-links d-none d-lg-block">
                                            {getInvoiceLink('invoice', 'Invoice')}
                                            <Conditional condition={!!invoice?.HasHourlyDetail}>
                                                | {getInvoiceLink('hourly', 'Hourly Detail')}
                                            </Conditional>
                                            <Conditional condition={!!invoice?.HasExpenseAttachments}>
                                                | {getInvoiceLink('attachment', 'Expense Attachments')}
                                            </Conditional>
                                        </Row>
                                        <Row className="f-doc-links d-lg-none">
                                            <a href='#' onClick={()=>{viewInvoice('invoice')}}>View Invoice <FontAwesomeIcon icon="external-link"></FontAwesomeIcon></a>
                                        </Row>
                                        <Conditional condition={!!invoice?.HasHourlyDetail}>
                                            <Row className="f-doc-links d-lg-none">
                                                <a href='#' onClick={()=>{viewInvoice('hourly')}}>Hourly Detail <FontAwesomeIcon icon="external-link"></FontAwesomeIcon></a>
                                            </Row>
                                        </Conditional>
                                        <Conditional condition={!!invoice?.HasExpenseAttachments}>
                                            <Row className="f-doc-links d-lg-none">
                                                <a href='#' onClick={()=>{viewInvoice('attachment')}}>Expense Attachments <FontAwesomeIcon icon="external-link"></FontAwesomeIcon></a>
                                            </Row>
                                        </Conditional>
                                    </Col>
                                    <Col className="f-amount-col" xs={12} lg={6}>
                                        <Row className='f-pay-links'>
                                            <Conditional condition={hasRemainingBalance()}>
                                                <Conditional condition={!!invoice?.CanPayWithACH}>
                                                    <Link to='/ach'>
                                                        <Button variant='success' onClick={() => { }}><FontAwesomeIcon icon='circle-check' />Pay With ACH</Button>
                                                    </Link>
                                                </Conditional>
                                                <Conditional condition={!!invoice?.CanPayWithCC}>
                                                    <Link to='/credit'>
                                                        <Button variant='success' onClick={() => { }}><FontAwesomeIcon icon='circle-check' /> Pay With Credit</Button>
                                                    </Link>
                                                </Conditional>
                                            </Conditional>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="d-none d-lg-block">
                                    <LoadableComponent loading={!invoicePdf}>
                                        <PdfViewer
                                            base64Pdf={invoicePdf}
                                        />
                                    </LoadableComponent>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs="12" lg="4">
                        <PaymentBreakdown screen="invoice" />
                    </Col>
                </Row>
            </Conditional>
            <Conditional condition={!!invoice && !invoice.CanBeViewed}>
                <Alert variant='danger'>This invoice is not available for viewing.</Alert>
            </Conditional>
        </ScrollablePage>
    );
};

export default ViewInvoice;