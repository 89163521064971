import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './page-loading.scss';

function PageLoading() {
    const [showReload, setShowReload] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowReload(true);
        }, 7000);
    }, []);

    const reloadPage = () => {
        window.location.reload();
    }

    return (
        <Container className="f-app-loading">
            <Row>
                <Col className='f-main-col' xs={12}>
                    <Row className={`f-factor-logo-container`}>
                        <span className={`f-factor-logo`} />
                    </Row>
                        <hr />
                    <Row className='f-table-graphic'>
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                    </Row>
                    {showReload && <p className='f-factor-reload-link'>Been waiting awhile? <a href='#' onClick={reloadPage}>Click here to refresh.</a></p>}
                </Col>
            </Row>
        </Container>
    )
}

export default PageLoading;