import TitleBar from "./title-bar";

function ProjectTitleBar() {

    return (
        <TitleBar
            title=''
        />
    );
}

export default ProjectTitleBar;