import ReceiptInfo from '@/models/receipt-info';
import Api from '@/services/api';

class ReceiptApi {
    getReceipt(paymentId: string, onSuccess: (receipt: ReceiptInfo) => void) {
        return Api.callApi({ url: `/api/v1/receipt/${paymentId}`, onSuccess });
    }

    emailReceipt(paymentId: string, email: string, onSuccess: () => void) {
        return Api.post({ url: `/api/v1/receipt/email`, body: { PaymentId: paymentId, ToEmailAddress: email }, onSuccess });
    }
}

export default new ReceiptApi();