import { msalInstance } from "@/main";
import { tokenRequest } from "./auth-config";
import { logException, logExceptionToSupport } from "./logging";
import { toast } from "react-toastify";

interface ApiOptions<T> {
    url: string;
    body?: any;
    file?: File;
    options?: any | null;
    ignoreWarnings?: boolean;
    rethrowUnknownError?: boolean;
    errorMessage?: string;
    onSuccess?: (res: T) => void;
    onKnownFailure?: (error: any, fallback: (error: any) => void) => void;
    includeContentType?: boolean;
    appendAccessToken?: boolean;
    apiService?: ApiService;
}

type ApiService = 'FactorPay'
    | 'Reporting';

class Api {
    headers(includeContentType: boolean) {
        let headers: any = {
            'Accept': 'application/json'
        };
        if (includeContentType)
            headers['Content-Type'] = 'application/json; charset=UTF-8';
        return headers;
    }    

    async openFile<T>({
        url,
        options = null,
        rethrowUnknownError = false,
        errorMessage = "There was an issue retrieving the file.",
        onSuccess = undefined,
        onKnownFailure = undefined
    }: ApiOptions<T>): Promise<T> {
        return this.callApi({ url, options: { responseType: "blob", ...options }, rethrowUnknownError, errorMessage, onSuccess, onKnownFailure });
    }

    async post<T>({
        url,
        body,
        options = null,
        rethrowUnknownError = true,
        errorMessage = "There was an issue saving changes.",
        onSuccess = undefined,
        onKnownFailure = undefined
    }: ApiOptions<T>): Promise<T> {
        let jsonBody = JSON.stringify(body);
        return this.callApi({ url, options: { method: "POST", body: jsonBody, ...options }, rethrowUnknownError, errorMessage, onSuccess, onKnownFailure });
    }

    async callApi<T>({
        url,
        options = null,
        rethrowUnknownError = false,
        errorMessage = "There was an issue retrieving data.",
        onSuccess = undefined,
        onKnownFailure = undefined,
        includeContentType = true,
        appendAccessToken = true,
        apiService = 'FactorPay',
    }: ApiOptions<T>): Promise<T> {
        if (appendAccessToken) {
            await msalInstance.acquireTokenSilent(tokenRequest).then(response =>
                options = { method: "GET", cache: 'no-cache', headers: { ...this.headers(includeContentType), "Authorization": `Bearer ${response.accessToken}` }, ...options }
            ).catch((error) => {
                if (error.errorCode === "consent_required" || error.errorCode === "interaction_required" || error.errorCode === "login_required" || error.errorCode === 'monitor_window_timeout') {
                    msalInstance.acquireTokenRedirect(tokenRequest);
                }
                console.log(error);
            });
        }
        else
            options = { method: "GET", cache: 'no-cache', headers: { ...this.headers(includeContentType) }, ...options }

        let isFile = options.responseType == "blob";

        const theIndex = {
            'FactorPay': 'REACT_APP_PROXY_URL',
            'Reporting': 'REACT_APP_REPORTING_URL',
        }

        let proxyUrl = theIndex[apiService];
        url = ((window as any)[proxyUrl] || window.location.origin) + url;

        return fetch(url, options)
            .then(res => { return this.handleResponse(res, rethrowUnknownError, errorMessage, isFile, onSuccess, onKnownFailure); })
            .catch((error: any) => {
                if (typeof error === "string") {
                    logException(new Error(error));
                } else if (error instanceof Error) {
                    logException(error);
                }
            });
    }

    handleResponse<T>(res: any, rethrowUnknownError: boolean, defaultErrorMessage: string, isFile: boolean, onSuccess?: any, onKnownFailure?: any) {
        if (res.ok) {
            if (res.status === 204)
                return onSuccess ? onSuccess() : null;
            if (isFile)
                return res.blob().then((blob: any) => {
                    window.open(window.URL.createObjectURL(blob));
                    onSuccess();
                });
            if (onSuccess)
                return res.text().then((content: any) => onSuccess(JSON.parse(content)));
            return res.json();
        }
        return this.handleErrorResponse(res, rethrowUnknownError, defaultErrorMessage, onKnownFailure);
    }

    handleErrorResponse(res: any, rethrowUnknownError: boolean, defaultErrorMessage: string, onKnownFailure?: any) {
        this.redirectIfAuthError(res);

        if (res.status >= 500 && res.status < 600)
            return this.handleInternalServerErrorPromise(res, rethrowUnknownError, defaultErrorMessage, onKnownFailure);
        return res.json().then((error: any) => {
            if (onKnownFailure)
                return onKnownFailure(error, this.knownErrorFallback);
            this.knownErrorFallback(error);
        });
    }

    handleInternalServerErrorPromise(res: any, rethrowUnknownError: boolean, defaultErrorMessage: string, onKnownFailure?: any) {
        return res.text().then((error: string) => {
            this.handleInternalServerError(error, rethrowUnknownError, defaultErrorMessage, onKnownFailure);
        });
    }

    handleInternalServerError(error: string, rethrowUnknownError: boolean, defaultErrorMessage: string, onKnownFailure?: any) {
        let errorMessage = new Error(error)
        if (rethrowUnknownError && !!onKnownFailure)
            onKnownFailure(defaultErrorMessage);
        else {
            toast.error(defaultErrorMessage);
            logExceptionToSupport(errorMessage);
        }
    }

    knownErrorFallback = (error: any) => {
        let errorText = this.getErrorText(error);
        if (errorText) {
            let errorMessage = new Error(errorText);
            logException(errorMessage);
            return true
        }
        return false;
    }

    getErrorText(error: any): string {
        let errorText: string;
        if (error.Message)
            errorText = error.Message;
        else if (error.title)
            errorText = error.title;
        else if (typeof error === 'string')
            errorText = error;
        else
            errorText = "There was an unexpected issue processing your request.";
        return errorText;
    }

    redirectIfAuthError(res: any) {
        // if (res.status === 417 || res.status === 401) {
        //     this.removeAccessToken();
        //     window.location.href = "/signin";
        // }
    }

    removeAccessToken() {
        if (localStorage.getItem("access_token"))
            localStorage.removeItem("access_token");
        else
            sessionStorage.removeItem("access_token");
    }
}

export default new Api();