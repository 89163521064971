import PageLoading from "@/components/page-loading";
import { AuthContext } from "@/contexts/auth-context-provider";
import { loginRequest } from "@/services/auth-config";
import { useMsal } from "@azure/msal-react";
import { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function Unauthorized() {
    const { instance } = useMsal();
    let [searchParams] = useSearchParams();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (!!authContext.idTokenHint) {
            instance.loginRedirect({
                scopes: loginRequest.scopes,
                extraQueryParameters: { id_token_hint: authContext.idTokenHint },
                redirectUri: window.location.protocol + '//' + window.location.host
            });
        }
    }, [authContext.idTokenHint]);

    useEffect(() => {
        var hint = searchParams.get('id_token_hint') || '';
        if (!!hint) {
            authContext.setIdTokenHint(hint);
        }
    }, []);

    return (
        <PageLoading/>
    )
}

export default Unauthorized;