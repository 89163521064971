interface Props {
    base64Pdf?: string;
}

function PdfViewer(props: Props) {

    return (
        <div className='f-pdf-viewer'>
            {!!props.base64Pdf &&
                <embed src={`data:application/pdf;base64,${props.base64Pdf}`} type="application/pdf" width="100%" height="100%"></embed>
            }
        </div>
    )
}

export default PdfViewer;